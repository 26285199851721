<template>
  <v-snackbar
    v-if="show"
    :value="show"
    multi-line
    right
    top
    max-width="39em"
    :timeout="-1"
    :color="color"
    class="trial-alert"
    content-class="d-flex align-start pl-5 py-8"
  >
    <template v-slot:action="{ attrs }">
      <v-btn icon v-bind="attrs" @click="show = false"><v-icon>mdi-close-circle</v-icon></v-btn>
    </template>

    <v-icon v-if="icon" class="pr-5">{{ icon }}</v-icon>

    <div class="content flex">
      <p class="title" v-if="title" v-html="title"></p>

      <p class="lines" v-for="(line, i) in content" :key="i" v-html="line"></p>
    </div>
  </v-snackbar>
</template>

<script>
import Vue from 'vue';

import { mapGetters } from 'vuex';
import { DateTime } from 'luxon';

export default Vue.extend({
  props: {
    trialEnd: {
      type: String,
      required: true,
    },

    color: {
      type: String,
      default: '#27A9E0',
    },
    icon: {
      type: String,
      default: 'mdi-information',
    },

    phone: {
      type: String,
      default: process.env.VUE_APP_SUPPORT_PHONE,
    },

    mail: {
      type: String,
      default: process.env.VUE_APP_SUPPORT_MAIL,
    },

    near: {
      type: Number,
      default: 14,
    },

    showOverdue: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      show: false,
      days: 0,
    };
  },

  computed: {
    ...mapGetters(['currentProfile']),

    phoneAttr() {
      return this.phone.replace(/[\s\(\)]+/, '');
    },

    content() {
      if (this.isNearEnd) {
        return [
          'After this time, you will lose access to the FlightScope Cloud premium features. You will still be able to log into your free account.',
          `To keep access to premium features please contact us via <a href="mailto:${this.mail}">email</a> or call our support: <strong><a href="tel:${this.phoneAttr}" class="text-nowrap">${this.phone}</a></strong>`,
        ];
      }
      if (this.showOverdue && this.isOverdue) {
        return [
          'You\'ve lost access to the FlightScope Cloud premium features.',
           `To regain access to premium features please contact us via <a href="mailto:${this.mail}">email</a> or call our support: <strong><a href="tel:${this.phoneAttr}" class="text-nowrap">${this.phone}</a></strong>`,
        ];
      }
      return [];
    },

    title() {
      if (this.isNearEnd) {
        return `Your trial subscription will expire in <strong>${this.days} days</strong>.`;
      }
      if (this.showOverdue && this.isOverdue) {
        return `Your trial subscription expired <strong>${this.days} days ago</strong>.`;
      }
      return '';
    },

    diff() {
      if (this.trialEnd) {
        const now = DateTime.utc();
        const end = DateTime.fromSQL(this.trialEnd, { timezone: 'UTC' });

        const { days } = end.diff(now, 'days').toObject();
        return days;
      }
      return null;
    },

    isNearEnd() {
      return this.diff !== null && this.diff > 0 && this.diff <= this.near;
    },

    isOverdue() {
      return this.diff !== null && this.diff <= 0;
    },
  },

  mounted() {
    if (this.isNearEnd || (this.showOverdue && this.isOverdue)) {
      this.show = true;
      this.days = Math.abs(Math.floor(this.diff));
    }
  },
});
</script>


<style scoped lang="scss">
.title {
  font-size: 16px;
  line-height: 1.3;
  font-weight: 400;
}

.lines {
  font-size: 12px;
  line-height: 1.3;
}

.lines:last-of-type {
  margin-bottom: 0;
}

.trial-alert::v-deep .v-snack__action {
  align-items: flex-start;
  align-self: stretch;
  margin-right: 0;
}
</style>
